var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticStyle: { height: "100vh" }, attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: { "align-self": "center", "justify-center": "" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-vertical-center text-center secondary--text"
                },
                [
                  _c("v-img", {
                    staticClass: "mb-12",
                    staticStyle: { margin: "0 auto" },
                    attrs: {
                      alt: "logo Al'",
                      width: "300px",
                      src: "/logo/logo.png"
                    }
                  }),
                  _c("h1", { staticClass: "font-weight-bold" }, [
                    _vm._v(" Il servizio non è al momento disponibile ")
                  ]),
                  _c("h3", [_vm._v("Ci scusiamo per il disagio!")]),
                  _c("h4", [
                    _vm._v(
                      "Ti invitiamo a ricaricare la pagina tra qualche minuto."
                    )
                  ]),
                  _c("h3", { staticClass: "font-weight-bold mt-5" }, [
                    _vm._v(
                      " Non puoi aspettare? contattaci al numero 049 8692211 "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-5 rounded-xl secondary--text",
                      attrs: {
                        color: "primary",
                        "x-large": "",
                        depressed: "",
                        to: { name: "Home" }
                      }
                    },
                    [_vm._v(" Ricarica la Homepage ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }